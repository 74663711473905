


























import { defineComponent } from '@vue/composition-api'
import { FlexibleFollowUp } from '@/inc/types'

export default defineComponent({
  name: 'FlexibleFollowUp',
  props: {
    content: {
      type: Object as () => FlexibleFollowUp,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
